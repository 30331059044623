import React from 'react';
import styled from '@emotion/styled';
import ApplyTemplate from 'components/Templates/ApplyTemplate';
import { graphql, withPrefix } from 'gatsby';
import { FluidObject } from 'gatsby-image';
import {
  IconShop1,
  IconShop2,
  IconShop3,
  IconShop4,
} from 'components/Atom/Icon';
import { Helmet } from 'react-helmet';

const Component = styled.div`
  width: 100%;
  height: 100%;
`;

interface MallProps {
  location: {
    pathname: string;
  };
  data: {
    logoNerdFactoryImg: {
      childImageSharp: {
        fluid: FluidObject;
      };
    };
    topFunctionImg: {
      childImageSharp: {
        fluid: FluidObject;
      };
    };
    img1_1: {
      childImageSharp: {
        fluid: FluidObject;
      };
    };
    img2_1: {
      childImageSharp: {
        fluid: FluidObject;
      };
    };
    img2_2: {
      childImageSharp: {
        fluid: FluidObject;
      };
    };
    img4: {
      childImageSharp: {
        fluid: FluidObject;
      };
    };
    img5: {
      childImageSharp: {
        fluid: FluidObject;
      };
    };
    img6: {
      childImageSharp: {
        fluid: FluidObject;
      };
    };
    img7: {
      childImageSharp: {
        fluid: FluidObject;
      };
    };
    thumbnail: {
      publicURL: string;
    };
  };
}

const Mall = ({ data, location }: MallProps) => {
  const title = 'VODA | 적용 | 온라인 쇼핑몰';
  const desc = 'VODA로 쇼핑몰 아이덴티티를 구축해보세요';
  return (
    <Component>
      <Helmet>
        <title>VODA | 적용 | 온라인 쇼핑몰</title>
        <meta
          name="description"
          content="VODA로 쇼핑몰 아이덴티티를 구축해보세요"
        />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta httpEquiv="Content-Type" content="text/html;charset=UTF-8" />

        <meta property="og:title" content={title} />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="<https://voda.nerdfactory.ai/apply/mall>"
        />
        <meta property="og:image" content={data.thumbnail.publicURL} />
        <meta property="og:description" content={desc} />
        <meta property="og:site_name" content={title} />
        <meta
          name="naver-site-verification"
          content="062e2e63e40411827d007a80f9f6ca2b16af6702"
        />
        <script src={withPrefix('script.js')} type="text/javascript" />
      </Helmet>
      <ApplyTemplate
        location={location}
        lookingForList={{
          title: `고객의 시선을 이해하고 공감할수록 구매까지 이어집니다.\n고객의 시그널을 놓치지 마세요`,
          list: [
            {
              Icon: IconShop1,
              desc: `방문 패턴을 파악하여\n고객 세분화 전략 수립`,
            },
            {
              Icon: IconShop2,
              desc: `사용성 분석으로\n제품(서비스) 선호도 조사`,
            },
            {
              Icon: IconShop3,
              desc: `효율적 타겟팅으로\n마케팅 비용 절감`,
            },
            {
              Icon: IconShop4,
              desc: `문제 요소를 파악하여\n쇼핑몰 개선점 도출`,
            },
          ],
        }}
        title="VODA 성공적으로\n장악하다"
        header={{
          sub: 'VODA 성공적으로 장악하다',
          main: '당신이 추구하는 \\n쇼핑몰 아이덴티티,\\nVODA를 통해 실현할 수 있습니다',
          long: '광고를 통한 고객 유입, 지속적으로 방문하는 로얄고객 비중,\\n구매를 망설이는 고객, 쇼핑몰 체류시간 등\\nVODA는 모든 고객의 행동 흐름을 분석하고 파악하여 고객을 세분화하고\\n잠재고객의 구매를 유도하는데 매우 유용합니다.',
        }}
        img={data.topFunctionImg.childImageSharp.fluid}
        logoNerdFactoryImg={data.logoNerdFactoryImg.childImageSharp.fluid}
        functionData={[
          {
            IllustImgList: [
              data.img2_1.childImageSharp.fluid,
              data.img2_2.childImageSharp.fluid,
            ],
            descData: {
              title: '정확한 타겟팅\\n클릭 히트맵',
              list: [
                {
                  title: '고객들이 관심을 갖는 요소는 무엇일까?',
                  desc: '페이지 내 클릭 분포와 클릭 집중 요소를 시각화하여 고객들에게 반응이 큰 콘텐츠를 알 수있습니다.',
                },
                {
                  title: '어떤 제품과 서비스에서 관심을 가지고 머물렀을까?',
                  desc: '어떤 콘텐츠에 관심을 가지고 오래 머물렀는지 고객의 관심 순위, 평균 관심 시간, 관심 비율을 파악합니다.',
                },
              ],
              to: '/functions/uxAnalysis',
              buttonTitle: '관련 기능 더 알아보기',
            },
            subTitle:
              '성공적으로 시장을 장악하고, 더 많은 고객이 선택하는 쇼핑몰이 되려면?',
            mainTitle:
              '어려운 기술과 복잡한 지식 없이도 고객을 분석하고 매출을 끌어올리세요',
            isBackGround: false,
            isDescLeft: false,
            width: 800,
            key: '0',
          },
          {
            IllustImgRawList: [data.img1_1.childImageSharp.fluid],
            descData: {
              title: '여정을 해석하다\\n사용자 이동경로 분석',
              list: [
                {
                  title: '고객은 어디로 들어와서 어디에서 나갈까?',
                  desc: '고객의 최초 유입부터 최종 이탈까지 전체 페이지의 유입 및 전환, 이탈 통계를 확인할 수 있습니다.',
                },
                {
                  title: '그냥 둘러보다가 나가는 고객, 왜일까?',
                  desc: '전환률이 낮은 페이지, 이탈율이 높은 페이지를 파악하여 고객 구매를 방해하는 요소, 문제가 되는 페이지를 유추합니다.',
                },
              ],
              // /pathPlot 위치로 스크롤
              to: '/functions/uxAnalysis',
              buttonTitle: '관련 기능 더 알아보기',
            },

            isBackGround: false,
            isDescLeft: true,
            width: 800,
            key: '1',
          },
        ]}
        recommendList={[
          {
            title: '의류/뷰티제품 쇼핑몰',
            desc: '트렌드에 민감한 소비자들,\n구매까지 유도하려면 어떻게 할까요?\nVODA가 해답을 찾아드려요.',
            img: data.img4.childImageSharp.fluid,
            key: '0',
          },
          {
            title: '가구/전제제품 쇼핑몰',
            desc: '신중하게 선택하는 품목들, 소비자의\n시선을 확 이끄는 요소가 궁금하세요?\nVODA로 구매자의 시선을 공감하세요.',
            img: data.img5.childImageSharp.fluid,
            key: '1',
          },
          {
            title: '여행/숙박/공연 분야 서비스',
            desc: '소비자 접속 후 예약과 구매까지\n원스톱으로 이어지고 있을까요?\nVODA가 전체 흐름을 읽습니다.',
            img: data.img6.childImageSharp.fluid,
            key: '2',
          },
          {
            title: '기타 쇼핑몰',
            desc: '우리의 제품이 소비자에게 매력적으로\n어필이 되고 있는 지 확신이 없다면?\nVODA가 통계로 보여드려요.',
            img: data.img7.childImageSharp.fluid,
            key: '3',
          },
        ]}
      />
    </Component>
  );
};

export default Mall;

export const queryData = graphql`
  query {
    logoNerdFactoryImg: file(name: { eq: "logo-nerd-factory@3x" }) {
      childImageSharp {
        fluid(fit: INSIDE, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    topFunctionImg: file(name: { eq: "img-top-apply@2x" }) {
      childImageSharp {
        fluid(fit: INSIDE, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    img1_1: file(name: { eq: "img-pathplot" }) {
      childImageSharp {
        fluid(fit: INSIDE, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    img2_1: file(name: { eq: "img-click-slice2-v2" }) {
      childImageSharp {
        fluid(fit: INSIDE, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    img2_2: file(name: { eq: "img-click-slice-v2" }) {
      childImageSharp {
        fluid(fit: INSIDE, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    img4: file(name: { eq: "img-shop-4@2x" }) {
      childImageSharp {
        fluid(fit: INSIDE, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    img5: file(name: { eq: "img-shop-5@2x" }) {
      childImageSharp {
        fluid(fit: INSIDE, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    img6: file(name: { eq: "img-shop-6@2x" }) {
      childImageSharp {
        fluid(fit: INSIDE, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    img7: file(name: { eq: "img-shop-7@2x" }) {
      childImageSharp {
        fluid(fit: INSIDE, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    thumbnail: file(name: { eq: "thumbnail-1280-720" }) {
      publicURL
    }
  }
`;
